import React from 'react';
import { NavLink } from 'react-router-dom';
import NoData from '../../Common/Wrapper/NoData';
import { BigArrowIcon } from '../../Common/Icon';

import styles from './index.module.scss';

export default function NotFound(): React.ReactNode {
  return (
    <div className={styles.notFound}>
      <NoData
        type="noFound"
        title={(
          <>
            <span style={{ fontWeight: 300 }}>Page</span>
            <br />
            <b>Not found</b>
          </>
        )}
        text="Sorry, we can't seem to find the page you're looking for. Let us help you find what you were looking for."
        link={(
          <NavLink to="/" className={styles.link}>
            <BigArrowIcon />
            Asset list
          </NavLink>
        )}
      />
    </div>
  );
}
