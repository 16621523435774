import React, { useEffect, useState } from 'react';
import { Layout as AntdLayout, theme } from 'antd';
import clsx, { ClassValue } from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import Menu from './Menu';
import { LogoIcon } from '../Common/Icon';
import Footer from '../Common/Footer';

import styles from './index.module.scss';

const { Header, Content } = AntdLayout;

interface LayoutProps {
  LeftMenu?: React.FC;
  leftMenu?: React.ReactNode;
  transparent?: boolean;
  disableLeftMenu?: boolean;
  children: React.ReactNode;
  collapsedByDefault?: boolean;
  minWidth?: boolean;
  className?: string | ClassValue;
}

let defaultStateCollapsed = false;

function Layout({
  disableLeftMenu = false,
  LeftMenu,
  leftMenu,
  transparent,
  children,
  collapsedByDefault = false,
  minWidth,
  className = '',
}: LayoutProps): React.ReactNode {
  const [collapsed, setCollapsed] = useState(defaultStateCollapsed);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const isTablet = useMediaQuery({
    query: '(max-width: 1023px)',
  });
  const isPhone = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const disableMenu = disableLeftMenu || (!LeftMenu && !leftMenu);

  useEffect(() => {
    defaultStateCollapsed = collapsed;
  }, [collapsed]);

  useEffect(() => {
    if ((isTablet && !disableMenu) || collapsedByDefault) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isTablet, disableMenu]);

  return (
    <AntdLayout hasSider className={styles.layout}>
      <AntdLayout
        className={clsx(
          styles.wrapper,
          { [styles.tablet]: isTablet && !isPhone && !disableMenu },
          { [styles.phone]: isPhone && !disableMenu },
        )}
      >
        <Header
          style={{ background: colorBgContainer }}
          className={clsx(
            styles.header,
            { [styles.headerLogo]: disableMenu },
          )}
        >
          <div className={styles.wrp}>
            {disableMenu ? (
              <NavLink
                to="/"
                className={clsx(
                  styles.logo,
                  { [styles.tablet]: isTablet && !isPhone },
                  { [styles.phone]: isPhone },
                )}
              >
                {/* {collapsed ? <Logo className={styles.logoSvg} /> : <Logo className={styles.logoSvg} />} */}
                <LogoIcon className={styles.logoSvg} />
              </NavLink>
            ) : null}

            <div className={styles.title}>
              ASSET PORTAL
            </div>

            <Menu />
          </div>
        </Header>
        <div className={clsx('scroll', styles.scroll, { [styles.collapsed]: collapsed })}>
          <Content
            className={clsx(
              styles.content,
              { [styles.transparent]: transparent },
              { [styles.minWidth]: isTablet && minWidth },
              className,
            )}
            id="layout-content"
          >
            {children}
          </Content>

          <div className={clsx(styles.footer, 'layout-footer')}>
            <Footer />
          </div>
        </div>
      </AntdLayout>
    </AntdLayout>
  );
}

export default Layout;
