import { AnyObject } from '../types';

export function getDataInStorage<T = AnyObject>(name: string): T {
  try {
    return JSON.parse(localStorage.getItem(name) || '{}');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`localStorage: ${name}`, error);
  }

  return {} as T;
}

export function setDataInStorage(
  name: string,
  data: AnyObject,
  marge = false,
): void {
  let newData = data;

  if (marge) {
    const oldData = getDataInStorage(name);

    newData = { ...oldData, ...data };
  }

  let str = '';

  try {
    str = JSON.stringify(newData);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`localStorage: ${name}`, error);
  }

  localStorage.setItem(name, str);
}
