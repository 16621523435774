import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './index.module.scss';

export interface ContentWrapperProps {
  className?: string | ClassValue;
  color: 'orange' | 'green' | 'default';
  text: string | React.ReactNode;
}

export default function Point({
  className = '',
  color = 'default',
  text,
}: ContentWrapperProps): React.ReactNode {
  return (
    <div className={clsx(styles.point, styles[color], className)}>
      {text}
    </div>
  );
}
