import React from 'react';
import clsx, { ClassValue } from 'clsx';

import { NavLink } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ArrowBreadcrumbsIcon, HomeIcon } from '../Icon';

import styles from './index.module.scss';
import Loading from '../Loading';

export interface BreadcrumbsProps {
  loading?: boolean;
  list: {
    name: string;
    to?: string;
  }[];
  className?: string | ClassValue;
}

export default function Breadcrumbs({ loading = true, list, className = '' }: BreadcrumbsProps): React.ReactNode {
  return (
    <div className={clsx(styles.breadcrumbs, className)}>
      <NavLink to="/">
        <HomeIcon />
      </NavLink>

      {loading ? (
        <Loading absolute />
      ) : list.map(({ name, to }, id) => (
        <div
          key={`${name + id}`}
          className={styles.item}
        >
          <ArrowBreadcrumbsIcon />

          {to ? (
            <NavLink to={to} className={styles.name}>
              {name}
            </NavLink>
          ) : (
            <div className={styles.name}>
              {name}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
