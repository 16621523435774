import React, { useState } from 'react';

import { Button, Input, Modal } from 'antd';
import { CloseIcon, DefaultImageIcon, PlusIcon } from '../../../Icon';

import styles from './index.module.scss';

interface AddCollectionProps {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function AddCollection({ isModalOpen, handleOk, handleCancel }: AddCollectionProps): React.ReactNode {
  const [isAdd, setAdd] = useState(false);

  return (
    <Modal
      width={420}
      title="Add to collection"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        setAdd(false);
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalAddCollection}
    >
      {isAdd ? (
        <div className={styles.addContent}>
          <div className={styles.new}>
            <DefaultImageIcon />
            <div className={styles.info}>
              <input
                placeholder="Title"
                onChange={(e) => {
                  // console.log(e);
                }}
              />
              <input
                placeholder="Description"
                onChange={(e) => {
                  // console.log(e);
                }}
              />
              <div>
                0 Items
              </div>
            </div>
          </div>

          <div className={styles.btnCenter}>
            <Button
              type="primary"
              size="large"
              style={{ marginTop: 24, fontSize: 14 }}
              onClick={(e) => {
                e.preventDefault();
                setAdd(true);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.noContent}>
            No collections added yet.
          </div>

          <div className={styles.btnCenter}>
            <Button
              icon={<PlusIcon />}
              type="text"
              size="large"
              style={{ marginTop: 16 }}
              className={styles.addCollection}
              onClick={(e) => {
                e.preventDefault();
                setAdd(true);
              }}
            >
              Create collection
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
