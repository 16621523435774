import React from 'react';
import clsx, { ClassValue } from 'clsx';
import styles from './index.module.scss';

export interface ContentWrapperProps {
  children: React.ReactNode
  className?: string | ClassValue;
}

export default function ContentWrapper({
  children,
  className = '',
}: ContentWrapperProps): React.ReactNode {
  return (
    <div className={clsx(styles.content, className)}>
      {children}
    </div>
  );
}
