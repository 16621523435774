import React from 'react';
import clsx, { ClassValue } from 'clsx';
import dayjs from 'dayjs';
import { AssetAllData, Restriction } from '../../../../../types/asset';

import styles from './index.module.scss';
import { InfoIcon } from '../../../../Common/Icon';
import Point from '../../../../Common/Point';

export interface RightsProps {
  data?: AssetAllData;
  className?: string | ClassValue;
}

const listRights: { [key: string]: (value: string) => JSX.Element } = {
  no_restrictions: () => <Point color="green" text="No restricted usage" />,
  listRights: () => <Point color="orange" text="Restricted usage" />,
  undefined: (value: string) => {
    console.log(value);

    if (value && value.length > 1) {
      return <Point color="green" text={value} />;
    }

    return <div>{value}</div>;
  },
};

export default function Rights({
  data = undefined, className = '',
}: RightsProps): React.ReactNode {
  if (!data) {
    return null;
  }

  const createRestriction = (
    key: string,
    restriction: Restriction | Restriction[],
    format?: string,
  ): React.ReactNode => {
    if (Array.isArray(restriction)) {
      return restriction.map((res) => createRestriction(key, res));
    }

    if (restriction.from) {
      return createRestriction(key, restriction.from, 'date');
    }

    if (restriction.to) {
      return createRestriction(key, restriction.to, 'date');
    }

    if (!restriction.value || !restriction.value.trim().replace('-', '')) {
      return null;
    }

    let { value } = restriction;

    if (format === 'date') {
      value = dayjs(value).format('MMM DD, YYYY');
    }

    return (
      <div key={value}>
        {listRights[value] ? listRights[value](value) : listRights.undefined(value)}
      </div>
    );
  };

  return (
    <div className={clsx(styles.rights, className)}>
      {Object.keys(data.restriction).map((key) => (
        <div className={styles.row} key={key}>
          <div>
            {key}
            <InfoIcon />
          </div>
          <div>
            {createRestriction(key, data?.restriction[key])}
          </div>
        </div>
      ))}
    </div>
  );
}
