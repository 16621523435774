import React from 'react';
import Layout from '../../components/Layout';
import ContentAccount from '../../components/Pages/Account';

export default function Account(): React.ReactNode | null {
  document.title = 'Account page';

  return (
    <Layout>
      <ContentAccount />
    </Layout>
  );
}
