import React, { useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Button, Modal } from 'antd';
import { NavLink } from 'react-router-dom';
import { AssetAllData } from '../../../../types/asset';

import styles from './index.module.scss';
import {
  AddCollectionIcon, DownloadIcon,
} from '../../../Common/Icon';
import Point from '../../../Common/Point';
import AddCollection from '../../../Common/Modal/Asset/AddCollection';
import { humanFileSize } from '../../../../utils';
import Download from '../../../Common/Modal/Asset/Download';

export interface InfoProps {
  loading?: boolean;
  data?: AssetAllData;
  className?: string | ClassValue;
}

export default function Info({ loading = true, data, className = '' }: InfoProps): React.ReactNode {
  const [isModalOpenCollection, setIsModalOpenCollection] = useState(false);
  const [isModalOpenDownload, setIsModalOpenDownload] = useState(false);

  const showModalCollection = () => {
    setIsModalOpenCollection(true);
  };

  const okCollection = () => {
    setIsModalOpenCollection(false);
  };

  const cancelCollection = () => {
    setIsModalOpenCollection(false);
  };

  const showModalDownload = () => {
    setIsModalOpenDownload(true);
  };

  const okDownload = () => {
    setIsModalOpenDownload(false);
  };

  const cancelDownload = () => {
    setIsModalOpenDownload(false);
  };

  const description = data?.metaDataFeature?.find(({ key }) => key === 'censhare:asset.description')?.value;

  return (
    <div className={clsx(styles.info, className)}>
      <div className={styles.title}>
        <div className={styles.type}>
          {data?.filesFile?.find(({ publicUrl }) => publicUrl)?.mimetype}
        </div>

        {data?.name?.replace(/_|-/gim, ' ')}
      </div>
      <div className={styles.rights}>
        Usage rights
        <div className={styles.usage}>
          {data?.restrictionsStatus === 'green' ? (
            <Point color="green" text="No restricted usage" />
          ) : (
            <Point color="orange" text="Restricted usage" />
          )}

          <a href="#UsageRights" className={styles.note}>
            Please note the details before use
          </a>
        </div>
      </div>
      <div className={styles.action}>
        <Button
          icon={<AddCollectionIcon style={{ transform: 'translate(0, 1.5px)' }} />}
          onClick={(e) => {
            e.preventDefault();
            showModalCollection();
          }}
        >
          Add to collection
        </Button>

        <AddCollection
          handleCancel={cancelCollection}
          handleOk={okCollection}
          isModalOpen={isModalOpenCollection}
        />

        <Button
          icon={<DownloadIcon />}
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            showModalDownload();
          }}
        >
          Download
          {' '}
          {humanFileSize(data?.filesFile?.find(({ publicUrl }) => publicUrl)?.filelength)}
        </Button>

        <Download
          isModalOpen={isModalOpenDownload}
          data={data}
          handleOk={okDownload}
          handleCancel={cancelDownload}
        />
      </div>
      <div className={styles.line} />
      {description && description.length > 0 ? (
        <div className={styles.description}>
          <div className={styles.name}>
            Description
          </div>
          <div className={styles.text}>
            {description}
          </div>
        </div>
      ) : null}
    </div>
  );
}
