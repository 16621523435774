import React from 'react';
import Layout from '../../components/Layout';
import ContentAsset from '../../components/Pages/Asset';

export default function Home(): React.ReactNode | null {
  document.title = 'Asset details';

  return (
    <Layout className="center">
      <ContentAsset />
    </Layout>
  );
}
