import {
  DefaultFetchError,
  FetchCreate, FetchDelete, FetchGet, FetchSuccess, PagingDataResponse, PagingParams,
  useFetchCreate, useFetchDelete, useFetchGet,
} from '../fetch';
import { Asset } from '../../types/asset';

export type CollectionGetParams = PagingParams

export interface CollectionGet {
  id: string;
  name: string;
  description: string;
  assets: Asset[];
  createdAt: string;
  updatedAt: string;
}

export const useCollectionGet = (): FetchGet<PagingDataResponse<CollectionGet>, CollectionGetParams> => useFetchGet(
  'collection',
  {
    autoStart: false, startStateLoading: false,
  },
);

export interface CollectionResponse {
  id: string;

  name: string;

  description: string;

  // assets: GetOneCollectionResponseDto[];

  createdAt: string;

  updatedAt: string;
}

export interface CollectionCreateForm {
  name: string;
  description?: string;
}

export interface CollectionCreateProps extends CollectionCreateForm {
  assets?: string[];
}

export const useCollectionCreate = (): FetchCreate<
  CollectionResponse,
  DefaultFetchError,
  CollectionCreateProps
> => useFetchCreate(
  'collection',
  {
    startStateLoading: false,
  },
);

export const useCollectionDelete = (id?: string): FetchDelete<
  FetchSuccess,
  DefaultFetchError
> => useFetchDelete('collection', id);

export default {
  useCollectionCreate,
};
