import React, { useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { NavLink } from 'react-router-dom';
import { AddCollectionIcon } from '../Icon';
import type { AssetAllData } from '../../../types/asset';
import Point from '../Point';
import AddCollection from '../Modal/Asset/AddCollection';

import styles from './index.module.scss';

export interface ContentWrapperProps {
  children?: React.ReactNode
  className?: string | ClassValue;
  to?: string;
  allData: AssetAllData;
}

export default function Asset({
  // children,
  to,
  className = '',
  allData,
}: ContentWrapperProps): React.ReactNode {
  const [showDefault, setShowDefault] = useState(false);
  const [isModalOpenCollection, setIsModalOpenCollection] = useState(false);

  const showModal = () => {
    setIsModalOpenCollection(true);
  };

  const handleOk = () => {
    setIsModalOpenCollection(false);
  };

  const handleCancel = () => {
    setIsModalOpenCollection(false);
  };

  const content = (
    <div className={styles.wrp}>
      <div className={styles.image}>
        {showDefault ? (
          <div
            className={styles.bg}
            title={allData.name}
            style={{
              backgroundImage: `url('${allData.filesFile?.find(({ publicUrl }) => publicUrl)?.publicUrl}'), `
                + 'url(\'/resource/images/asset.png\')',
            }}
          />
        ) : (
          <img
            src={`${process.env.REACT_APP_API}assets/${allData.id}.jpeg`}
            alt={allData.name}
            title={allData.name}
            onError={() => setShowDefault(true)}
          />
        )}
      </div>
      <div className={styles.type}>
        <span className={styles.text}>
          <span>
            {allData.type}
          </span>
          <span>
            {allData.filesFile?.find(({ publicUrl }) => publicUrl)?.mimetype}
          </span>
        </span>
        <span
          role="none"
          className={styles.add}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            showModal();
          }}
        >
          <AddCollectionIcon />
        </span>
      </div>
      <div className={styles.name}>
        {allData.name}
      </div>
      <div className={styles.status}>
        {allData?.restrictionsStatus === 'green' ? (
          <Point color="green" text="No restricted usage" />
        ) : (
          <Point color="orange" text="Restricted usage" />
        )}
      </div>
    </div>
  );

  if (to) {
    return (
      <>
        <NavLink to={to} className={clsx(styles.asset, className)}>
          {content}
        </NavLink>

        <AddCollection
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalOpen={isModalOpenCollection}
        />
      </>
    );
  }

  return (
    <div className={clsx(styles.asset, className)}>
      {content}

      <AddCollection
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpenCollection}
      />
    </div>
  );
}
