import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '../types';
import { getDataInStorage, setDataInStorage } from '../utils/storage';

export const KEY = 'lang';
export const LANG_LIST = ['eng', 'de'];
export const LANG_LIST_FULL = ['English', 'Germany'];

interface Lang {
  value: string;
}

export function useWatchLocalLang<T = AnyObject>(name: string, initValue: T):
  [T, (key: string, value: T & AnyObject) => void] {
  const [state, setState] = useState(getDataInStorage<T>(name) || initValue);

  useEffect(() => {
    const fn = () => {
      if (JSON.stringify(state) !== window.localStorage.getItem(name)) {
        setState(getDataInStorage(name));
      }
    };

    window.addEventListener('storage', fn, false);

    return () => window.removeEventListener('storage', fn);
  }, []);

  return [state || initValue, (key: string, value: T & AnyObject) => {
    setState(value);
    setDataInStorage(key, value);
  }];
}

interface LangProviderProps {
  lang: Lang
  setLang: (value: Lang) => void;
}

const defaultValue: LangProviderProps = {
  lang: { value: LANG_LIST[0] },
  setLang: (value: Lang) => {},
};

export const LangContext = createContext<LangProviderProps>(defaultValue);

function LangProvider({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();
  const [state, setState] = useState<Lang>(getDataInStorage(KEY));

  useEffect(() => {
    const fn = () => {
      if (JSON.stringify(state) !== window.localStorage.getItem(KEY)) {
        setState(getDataInStorage(KEY));
      }
    };

    window.addEventListener('storage', fn, false);

    return () => window.removeEventListener('storage', fn);
  }, []);

  useEffect(() => {
    if (state && state.value) {
      i18n.changeLanguage(state.value);
    }
  }, [state]);

  const memoizedValue = useMemo(() => ({
    lang: state,
    setLang: (value: Lang) => {
      setState(value);
      setDataInStorage(KEY, value);
    },
  }), [state]);

  return (
    <LangContext.Provider value={memoizedValue}>
      {children}
    </LangContext.Provider>
  );
}

export default LangProvider;

export const useLangProvider = (): LangProviderProps => useContext(LangContext);
