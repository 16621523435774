import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { AssetByIdGet } from '../../../../../hooks/api/asset';

import styles from './index.module.scss';
import { humanFileSize } from '../../../../../utils';

export interface PropertiesProps {
  data?: AssetByIdGet;
  className?: string | ClassValue;
}

export default function Properties({
  data = undefined, className = '',
}: PropertiesProps): React.ReactNode {
  return (
    <div className={clsx(styles.properties, className)}>
      <div className={styles.row}>
        <div>ID</div>
        <div>{data?.assetId}</div>
      </div>
      <div className={styles.row}>
        <div>Brand</div>
        <div>
          {data?.brands && data?.brands.length ? data?.brands.map(({ id, text }: { id: string; text: string }) => (
            <div key={id}>{text}</div>
          )) : null}
        </div>
      </div>
      <div className={styles.row}>
        <div>Intended Use</div>
        <div>-</div>
      </div>
      <div className={styles.row}>
        <div>Other</div>
        <div>-</div>
      </div>
      <div className={styles.row}>
        <div>Producer</div>
        <div>-</div>
      </div>
      <div className={styles.row}>
        <div>Upload MIME Type</div>
        <div>{data?.format || '-'}</div>
      </div>
      <div className={styles.row}>
        <div>Upload File Size</div>
        <div>{humanFileSize(data?.allData?.filesFile?.find(({ publicUrl }) => publicUrl)?.filelength)}</div>
      </div>
    </div>
  );
}
