import React from 'react';
import Layout from '../../components/Layout';
import ContentCollection from '../../components/Pages/Collection';

export default function Collections(): React.ReactNode | null {
  document.title = 'Collections';

  return (
    <Layout className="center">
      <ContentCollection />
    </Layout>
  );
}
