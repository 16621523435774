import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.REACT_APP_ENV === 'local',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          order: {
            'from-new-to-old': 'From newest to oldest',
            'from-old-to-new': 'From oldest to newest',
            'from-0-9': 'From 0 to 9, A-Z',
            'from-9-0': 'From 9 to 0, Z-A',
          },
        },
      },
      de: {
        translation: {
          order: {
            'from-new-to-old': 'Vom Neusten zum Ältesten',
            'from-old-to-new': 'Vom Ältesten zum Neusten',
            'from-0-9': 'Von 0 bis 9, A-Z',
            'from-9-0': 'Von 9 bis 0, Z-A',
          },
        },
      },
    },
  });

export default i18n;
