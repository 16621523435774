import { SortOrder } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { AnyObject } from '../types';

export function translateStatus(isActive?: boolean): string {
  return isActive ? 'Active' : 'Inactive';
}

export function translateStatusAction(isActive?: boolean): string {
  return isActive ? 'Activate' : 'Deactivate';
}

export function capitalize(str = 'undefined'): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeFirstLetter(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
export function getParsedJwt<T = AnyObject>(
  token?: string,
): T | undefined {
  try {
    return token ? JSON.parse(atob(token.split('.')[1])) : undefined;
  } catch {
    return undefined;
  }
}

export const getSorterParams = (sorter: Record<string, SortOrder>): { [key: string]: string; } => {
  const sorterEntries = Object.entries(sorter);
  const newParams: { [key: string]: string; } = {};

  if (sorterEntries.length > 0) {
    newParams.orderByColumn = sorterEntries[0][0] || '';
    newParams.orderBy = sorterEntries[0][1] === 'ascend' ? 'ASC' : 'DESC';
  }

  return newParams;
};

export const queryFilterParams = (queryParams: Record<string, string>): Record<string, string> => {
  const params = Object.entries(queryParams);
  const newParams: { [key: string]: string; } = {};

  for (const [key, value] of params) {
    if (value) {
      newParams[key] = value.toString();
    }
  }

  return newParams;
};

export const getBase64 = (file: Blob): Promise<unknown> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.addEventListener('load', () => resolve(reader.result), false);
  reader.addEventListener('error', (error) => reject(error), false);
});

export const generateRandomString = (): string => Math.random().toString(36).slice(2, 8);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayEquals = <T = any>(a: T[], b: T[]): boolean => {
  if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    const sortedA = a.sort((prev, next) => (prev < next ? 1 : -1));
    const sortedB = b.sort((prev, next) => (prev < next ? 1 : -1));

    return sortedA.every((val, index) => val === sortedB[index]);
  }

  return false;
};

export interface ValidSearchParams {
  [key: string]: string | string[]
}

export function getValidSearchParams(
  listValidParams: string[] | '*',
  searchParams: URLSearchParams,
  defaultParams?: { [key: string]: number | string | string[] },
): ValidSearchParams {
  const props = {};
  const fn = (key: string) => {
    const value = searchParams.getAll(key);

    if (value.length > 0 || !(value.length === 1 && value[0] === '')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props[key] = value.length === 1 ? value[0] : value;
    } else if (defaultParams) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props[key] = typeof defaultParams[key] === 'number' ? defaultParams[key].toString() : defaultParams[key];
    }
  };

  if (listValidParams === '*') {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
      fn(key);
    }
  } else {
    listValidParams.forEach(fn);
  }

  return props;
}

export function getValidSearchParamsWithout(
  excludeList: string[],
  searchParams: URLSearchParams,
  listValidParams: string[] | '*' = '*',
): ValidSearchParams {
  const list = getValidSearchParams(listValidParams, searchParams);

  for (const key of excludeList) {
    if (typeof list[key] !== 'undefined') {
      delete list[key];
    }
  }

  return list;
}

interface Date {
  $y: number;
  $M: number;
  $D: number;
  $H?: number;
  $m?: number;
}
interface Time {
  $H: number;
  $m: number;
}

export const getDateTimeISO = (date: Date, time: Time) => {
  if (!date || !time) {
    return null;
  }
  const formattedDate = new Date(Date.UTC(date.$y, date.$M, date.$D, time.$H, time.$m));

  return formattedDate.toISOString();
};

export const getDateISO = (date: Date) => {
  if (!date) {
    return null;
  }
  const formattedDate = new Date(Date.UTC(date.$y, date.$M, date.$D, date.$H, date.$m));

  return formattedDate.toISOString();
};

export const getDaysBetween = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  return `${dayjs(startDate)?.utc()?.format('ddd, MMM D')} - ${dayjs(endDate)?.utc()?.format('ddd, MMM D')}`;
};

export const getHoursBetween = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  return `${dayjs(startDate).utc()?.format('HH:mm')} - ${dayjs(endDate).utc()?.format('HH:mm')}`;
};

export const getDayJsTimeBetween = (startDate: string, endDate: string) => (
  [dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'), dayjs(endDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')]
);

interface ArrayOfIdsProps {
  id: string
}

export const getArrayOfIds = (array: ArrayOfIdsProps[]) => array.map((item) => item.id);

type EnumMap<T> = { [key: string]: T };
export function enumToOptionsArray<T extends EnumMap<string>>(enumObj: T): { value: string; label: string }[] {
  return Object.entries(enumObj).map(([key, value]) => ({ value: key, label: value }));
}

export function getOnlyValueRecursive(value: AnyObject | AnyObject[]): string {
  if (value === null || typeof value !== 'object') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((v) => (v !== null && typeof v === 'object' ? getOnlyValueRecursive(v) : v)).join('');
  }

  return Object.values(value).map(getOnlyValueRecursive).join('');
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes?: number | string, si = true, dp = 1): string {
  if (!bytes) {
    return '-';
  }

  let byte = typeof bytes === 'string' ? Number.parseFloat(bytes) : bytes;

  const thresh = si ? 1000 : 1024;

  if (Math.abs(byte) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    byte /= thresh;
    ++u;
  } while (Math.round(Math.abs(byte) * r) / r >= thresh && u < units.length - 1);

  return `${byte.toFixed(dp)} ${units[u]}`;
}

export interface ForceDownload {
  loading: boolean;
  start: (url: string, fileName: string) => void;
}

export function useForceDownload(): ForceDownload {
  const [loading, setLoading] = useState(false);

  return {
    loading,
    start: (url: string, fileName: string) => {
      setLoading(true);
      const xhr = new XMLHttpRequest();

      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.addEventListener('load', function () {
        setLoading(false);
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const link = document.createElement('a');

        link.style.opacity = '0';
        link.style.width = '1px';
        link.style.height = '1px';
        link.href = imageUrl;
        link.download = fileName;
        document.body.append(link);
        link.click();
        link.remove();
      });
      xhr.send();
    },
  };
}
