import React, {
  RefObject, useEffect, useRef, useState,
} from 'react';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ContentWrapper from '../../Common/Content/Wrapper';
import Filter from '../../Common/Filter';
import AssetWrapper from '../../Common/Asset/Wrapper';
import Asset from '../../Common/Asset';
import {
  ArrowLeftIcon, ArrowRightIcon, ArrowTopIcon,
} from '../../Common/Icon';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useFilter } from './Context/filter';
import SelectedFilters from '../../Common/SelectedFilters';

import styles from './index.module.scss';
import Loading from '../../Common/Loading';

export default function Home(): React.ReactNode {
  const ref = useRef<any>();
  const [_, setSearchParams, searchParams] = useSearchParams({}, false);
  const {
    state, total, asset,
  } = useFilter();
  const [isShowScrollTop, setShowScrollTop] = useState(false);
  const footerElement = document.querySelector('.layout-footer');
  const footerHeight = footerElement ? footerElement.getBoundingClientRect().height : 156;

  useEffect(() => {
    const onScroll = ({ target }: any) => {
      setShowScrollTop(target?.scrollTop > 300
        // eslint-disable-next-line no-unsafe-optional-chaining
        && target?.scrollHeight - target?.scrollTop - target?.getBoundingClientRect().height >= -1);

      if (ref.current && ref.current.closest('body')) {
        const scrollTop = (target.scrollHeight || 0)
          - (target?.scrollTop || 0)
          - (target.getBoundingClientRect().height || 0);

        ref.current.style.bottom = `${(scrollTop > footerHeight ? 0 : footerHeight - scrollTop) + 24}px`;
      }
    };
    const scrollElement = document.querySelector('.ant-layout .scroll');

    if (scrollElement) {
      scrollElement.addEventListener('scroll', onScroll, false);
    }
    document.body.addEventListener('scroll', onScroll, false);

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', onScroll);
      }
      document.body.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <ContentWrapper>
      <Filter>
        <SelectedFilters />

        <AssetWrapper>
          {asset.data?.data.map(({
            assetId, allData,
          }) => (
            <Asset key={assetId} to={`asset/${assetId}`} allData={allData} />
          ))}
        </AssetWrapper>

        <div className={styles.pagination}>
          <Pagination
            onChange={(value) => setSearchParams({
              ...searchParams,
              page: value.toString(),
            })}
            current={state.page}
            total={total}
            pageSize={state.pageSize}
            showSizeChanger={false}
            hideOnSinglePage
            showTitle={false}
            nextIcon={<ArrowRightIcon />}
            prevIcon={<ArrowLeftIcon />}
          />
        </div>
      </Filter>

      {asset.loading ? (
        <Loading absolute />
      ) : null}

      {isShowScrollTop ? (
        <div
          ref={ref}
          role="none"
          className={styles.moveToTop}
          onClick={(e) => {
            e.preventDefault();
            document.querySelector('.ant-layout .scroll')?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
        >
          <ArrowTopIcon />
        </div>
      ) : null}
    </ContentWrapper>
  );
}
