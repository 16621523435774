import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { Carousel, Image } from 'antd';
import { FilesFile } from '../../../../types/asset';
import { ZoomIcon } from '../../../Common/Icon';

import styles from './index.module.scss';

export interface GalleryProps {
  loading?: boolean;
  list?: FilesFile[];
  className?: string | ClassValue;
}

export default function Gallery({ loading = true, list, className = '' }: GalleryProps): React.ReactNode {
  let listFiles = list?.filter(({ publicUrl }) => publicUrl);

  if (!listFiles || listFiles.length === 0) {
    listFiles = [{ publicUrl: '/resource/images/asset.png' }] as FilesFile[];
  }

  return (
    <div className={clsx(styles.gallery, className)}>
      <Carousel arrows infinite={false} dots={false}>
        {listFiles.map(({ publicUrl, name, type }) => (
          (type === 'video' || type === 'export') && publicUrl?.includes('mp4') ? (
            <Image
              key={publicUrl}
              alt={name}
              title={name}
              width="auto"
              preview={{
                destroyOnClose: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                imageRender: () => (
                  <video
                    muted
                    controls
                    src={publicUrl}
                    className={styles.video}
                  />
                ),
                toolbarRender: () => null,
              }}
              src="/resource/images/asset.png"
            />
          ) : (
            <Image
              key={publicUrl}
              alt={name}
              title={name}
              // width={400}
              src={/.*\.jpeg|jpg|png/.test(publicUrl || '') ? publicUrl : '/resource/images/asset.png'}
              placeholder={(
                <Image
                  preview={false}
                  src="/resource/images/asset.png"
                  width={200}
                />
              )}
            />
          )
        ))}
      </Carousel>
      <ZoomIcon className={styles.zoom} />
    </div>
  );
}
