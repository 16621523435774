import { ConfigProvider, App } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import React from 'react';

interface AntdConfigProviderProps {
  children: React.ReactNode
}

export const BUTTON_COLOR_PRIMARY = '#376EB4';
export const BUTTON_COLOR_PRIMARY_HOVER = '#0A5A96';

export const COLOR_PRIMARY = BUTTON_COLOR_PRIMARY;
export const COLOR_PRIMARY_HOVER = BUTTON_COLOR_PRIMARY_HOVER;
export const COLOR_PRIMARY_ACTIVE = '#004673';

export default function AntdConfigProvider({ children }: AntdConfigProviderProps): React.ReactNode {
  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        // https://ant.design/docs/react/customize-theme
        components: {
          Button: {
            borderRadiusSM: 0,
            borderRadiusXS: 0,
            borderRadiusLG: 0,
            borderRadiusOuter: 0,
            borderColorDisabled: 'rgba(0,0,0,0)',
          },
          // Input: {
          //   colorPrimary: '#eb2f96',
          //   algorithm: true, // Enable algorithm
          // },
          Select: {
            controlPaddingHorizontal: 8,
            optionPadding: '10px 8px',
          },
        },

        token: {
          fontFamily: 'AktivGrotesk,Helvetica,Arial,sans-serif',
          colorPrimary: BUTTON_COLOR_PRIMARY,
          colorPrimaryHover: BUTTON_COLOR_PRIMARY_HOVER,
          colorBgContainerDisabled: 'rgba(0,0,0,0.08)',
          colorLink: COLOR_PRIMARY,
          colorLinkHover: COLOR_PRIMARY_HOVER,
          colorLinkActive: COLOR_PRIMARY_ACTIVE,
          borderRadius: 0,
        },
      }}
    >
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
}
