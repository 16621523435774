import React from 'react';
import clsx, { ClassValue } from 'clsx';
import NoResult from '../../../Pages/NoResult';

import './index.scss';
import styles from './index.module.scss';

export interface ContentWrapperProps {
  children?: React.ReactNode[];
  className?: string | ClassValue;
}

export default function AssetWrapper({
  children,
  className = '',
}: ContentWrapperProps): React.ReactNode {
  return (
    <div className={clsx('assetWrapper', { [styles.noResult]: !children || !children?.length }, className)}>
      {(children && !children?.length) ? (
        <NoResult />
      ) : children}
    </div>
  );
}
