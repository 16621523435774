import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { AssetAllData } from '../../../../../types/asset';

import styles from './index.module.scss';
import { BigArrowIcon } from '../../../../Common/Icon';

export interface DescriptionProps {
  data?: AssetAllData;
  className?: string | ClassValue;
}

export default function Description({
  data = undefined, className = '',
}: DescriptionProps): React.ReactNode {
  return (
    <div className={clsx(styles.description, className)}>
      <div className={styles.row}>
        <div>Type</div>
        <div>
          <a href="some.com">
            <BigArrowIcon />
            Photo
          </a>
        </div>
      </div>
      <div className={styles.row}>
        <div>Product</div>
        <div>-</div>
      </div>
      <div className={styles.row}>
        <div>Article</div>
        <div>-</div>
      </div>
      <div className={styles.row}>
        <div>With person</div>
        <div>-</div>
      </div>
      <div className={styles.row}>
        <div>With person</div>
        <div>-</div>
      </div>
      <div className={styles.row}>
        <div>Campaign</div>
        <div>
          <a href="some.com">
            <BigArrowIcon />
            Online catalogue
          </a>
        </div>
      </div>
    </div>
  );
}
