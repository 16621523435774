import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { AnyObject } from '../../../../types';
import {
  AssetFilterGet,
  useAssetFilterGet,
  useAssetGet,
  AssetGet,
  AssetGetParams,
  useAssetFilterAvailableGet,
  AssetFilterAvailableGet,
} from '../../../../hooks/api/asset';
import useComponentDidUpdate from '../../../../hooks/componentDidUpdate';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import {
  FetchGet, PagingDataResponse,
} from '../../../../hooks/fetch';

export interface Filter extends AssetGetParams {
  search: string;
}

export const getMinPageSize = (): number => {
  if (window.innerWidth > 3800 || (window.innerWidth * window.innerHeight) > 3800 * 1300) {
    return 72;
  }

  if (window.innerWidth > 2120 || (window.innerWidth * window.innerHeight) > 2120 * 1100) {
    return 48;
  }

  if (window.innerWidth > 1522 || (window.innerWidth * window.innerHeight) > 1522 * 850) {
    return 24;
  }

  return 12;
};

const defaultFilters: AnyObject = {
  pageSize: getMinPageSize(),
  page: 1,
  orderBy: 'From newest to oldest',
};

const filters: Partial<Filter> = {
  ...defaultFilters,
  search: '',
};

interface FilterProviderProps {
  asset: Partial<FetchGet<PagingDataResponse<AssetGet>, AssetGetParams>>;
  isFilterOpen: boolean,
  setFilterOpen: (status: boolean) => void,
  allFilter: Partial<FetchGet<AssetFilterGet, null>>;
  filterAvailable: Partial<FetchGet<AssetFilterAvailableGet, AssetGetParams>>;
  state: Partial<Filter>;
  setState: (value: Partial<Filter>) => void;
  total: number;
  setTotal: (value: number) => void;
}

const defaultValue: FilterProviderProps = {
  asset: {
    data: {
      data: [],
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    },
    response: undefined,
    loading: false,
    error: null,
  },
  isFilterOpen: false,
  setFilterOpen: () => {},
  allFilter: {
    data: undefined,
    response: undefined,
    loading: false,
    error: null,
  },
  filterAvailable: {
    data: undefined,
    response: undefined,
    loading: false,
    error: null,
  },
  state: filters,
  setState: () => {},
  total: 0,
  setTotal: () => {},
};

export const FilterContext = createContext<FilterProviderProps>(defaultValue);

const createParams = (searchParams: AnyObject) => {
  const obj: Partial<Filter> = { ...searchParams };

  Object.keys(defaultFilters).forEach((key) => {
    if (!obj[key]) {
      obj[key] = defaultFilters[key];
    }
  });

  return obj;
};

export default function FilterProvider({ children }: { children: ReactNode }) {
  const allFilter = useAssetFilterGet();
  const filterAvailable = useAssetFilterAvailableGet();
  const [_, __, searchParams] = useSearchParams({}, false);
  const [state, setState] = useState<Partial<Filter>>(createParams(searchParams));
  const [total, setTotal] = useState<number>(0);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const asset = useAssetGet();

  useEffect(() => {
    asset.fetch(state);

    const {
      page, pageSize, orderBy, ...params
    } = state;

    filterAvailable.fetch(params);
  }, [state]);

  useEffect(() => {
    if (!asset.loading && !asset.error && asset.response) {
      setTotal(asset.response.data.meta.itemCount);
      setTimeout(() => {
        document.querySelector('.ant-layout .scroll')?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        document.body.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 50);
    }
  }, [asset.response]);

  useComponentDidUpdate(() => {
    setState(createParams(searchParams));
  }, [searchParams]);

  const memoizedValue = useMemo(() => ({
    asset,
    filterAvailable,
    isFilterOpen,
    setFilterOpen,
    allFilter,
    state,
    setState,
    total,
    setTotal,
  }), [
    isFilterOpen,
    state,
    total,
    asset.loading,
    asset.data,
    asset.error,
    allFilter.data,
    allFilter.loading,
    allFilter.error,
    filterAvailable.data,
    filterAvailable.loading,
    filterAvailable.error,
  ]);

  return (
    <FilterContext.Provider value={memoizedValue}>
      {children}
    </FilterContext.Provider>
  );
}

export const useFilter = (): FilterProviderProps => useContext(FilterContext);
