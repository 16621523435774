import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContentWrapper from '../../Common/Content/Wrapper';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { useAssetByIdGet } from '../../../hooks/api/asset';
import Gallery from './Gallery';
import Info from './Info';
import CollapseInfo from './CollapseInfo';
import Properties from './CollapseInfo/Properties';
import Description from './CollapseInfo/Description';
import Rights from './CollapseInfo/Rights';
import NotFound from '../NotFound';

import styles from './index.module.scss';

export default function Asset(): React.ReactNode {
  const { assetId } = useParams();
  const asset = useAssetByIdGet();

  useEffect(() => {
    asset.fetch(undefined, assetId);
  }, [assetId]);

  useEffect(() => {
    if (asset.data && !asset.loading && !asset.error) {
      document.title = `Asset: ${asset.data?.name}`;
    }
  }, [asset.data]);

  if (asset.error) {
    return (
      <ContentWrapper className={styles.wrapper}>
        <NotFound />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper className={styles.wrapper}>
      <Breadcrumbs
        loading={asset.loading}
        list={[{
          name: asset.data?.name || '',
        }]}
      />

      <div className={styles.main}>
        <Gallery list={asset.data?.allData.filesFile} loading={asset.loading} />
        <Info data={asset.data?.allData} loading={asset.loading} />
      </div>

      <div className={styles.collapse}>
        {/* <Keywords data={asset.data?.allData} /> */}

        <CollapseInfo type="Properties">
          <Properties data={asset.data} />
        </CollapseInfo>
        <CollapseInfo type="Description">
          <Description data={asset.data?.allData} />
        </CollapseInfo>

        {Object.keys(asset.data?.allData?.restriction || {}).length > 0 ? (
          <CollapseInfo type="Usage rights">
            <Rights data={asset.data?.allData} />
          </CollapseInfo>
        ) : null}
      </div>
    </ContentWrapper>
  );
}
