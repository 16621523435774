import React from 'react';

import styles from './index.module.scss';
import NoData from '../../Common/Wrapper/NoData';

export default function NoResult(): React.ReactNode {
  return (
    <div className={styles.notResult}>
      <NoData
        type="noResult"
        title={(
          <>
            <span style={{ fontWeight: 300 }}>no results</span>
            <br />
            <b>found</b>
          </>
        )}
        text="Sorry, we couldn&apos;t find any results. Please check your spelling or try different keywords."
      />
    </div>
  );
}
