import React from 'react';
import Simple from '../../components/Layout/Simple';

export default function NotFound(): React.ReactNode {
  document.title = 'Not found';

  return (
    <Simple>
      <div>Not found</div>
    </Simple>
  );
}
