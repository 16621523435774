import dayjs from 'dayjs';
import {
  FetchGet, FetchGetId, PagingDataResponse, PagingParams, useFetchGet, useFetchGetId,
} from '../fetch';
import { Asset, AssetAllData, AssetFilterItem } from '../../types/asset';
import { AnyObject } from '../../types';

export type AssetGetParams = PagingParams

export interface AssetGet {
  assetId: number;
  name: string;
  allData: AssetAllData;
  updatedAt: string;
}

export const useAssetGet = (): FetchGet<PagingDataResponse<AssetGet>, AssetGetParams> => useFetchGet(
  'asset',
  {
    autoStart: false, startStateLoading: false,
  },
);

export interface AssetByIdGet extends Omit<Asset, 'updatedAt'> {
  updatedAt: dayjs.Dayjs;
}

export const useAssetByIdGet = (): FetchGetId<AssetByIdGet, string> => useFetchGetId(
  'asset',
  '',
  {
    autoStart: false,
    startStateLoading: false,
    latest: true,
    decorateData: (data) => ({ ...data, updatedAt: dayjs(data.updatedAt) }),
  },
);

export interface AssetFilterGet extends AnyObject {
  pagination: string[];

  order: string[];

  mediaType: string[];

  restriction: string[];

  format: string[];

  language: string[];

  publicationType: string[];

  publicationYear: string[];

  reference: string[];

  event: string[];

  brands: AssetFilterItem[];

  campaigns: AssetFilterItem[];

  keywords: AssetFilterItem[];

  locations: AssetFilterItem[];

  persons: AssetFilterItem[];

  series: AssetFilterItem[];

  statements: AssetFilterItem[];

  surroundings: AssetFilterItem[];

  systems: AssetFilterItem[];

  tools: AssetFilterItem[];

  types: AssetFilterItem[];
}

export const useAssetFilterGet = (): FetchGet<AssetFilterGet, null> => useFetchGet(
  'asset/filter-all-data',
  {
    autoStart: true, startStateLoading: false, latest: true,
  },
);

export interface AssetFilterAvailableGet {
  [key: string]: string[]
}

export const useAssetFilterAvailableGet = (): FetchGet<AssetFilterAvailableGet, AssetGetParams> => useFetchGet(
  'asset/available-filter',
  {
    autoStart: false, startStateLoading: false, latest: true,
  },
);

export default {
  useAssetGet,
  useAssetFilterGet,
  useAssetFilterAvailableGet,
};
