import React, { useState } from 'react';

import { DownloadIcon } from '../../../../Common/Icon';
import DownloadModal from '../../../../Common/Modal/Collection/Download';

export interface DownloadProps {
  id: string;
}

export default function Download({ id }: DownloadProps): React.ReactNode {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const ok = () => {
    setIsModalOpen(false);
  };

  const cancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        role="none"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showModal();
        }}
      >
        <DownloadIcon />
      </div>
      <DownloadModal
        isModalOpen={isModalOpen}
        handleOk={ok}
        handleCancel={cancel}
      />
    </>
  );
}
