import React from 'react';

import { Button, Modal } from 'antd';
import { CloseIcon } from '../../../Icon';
import { AssetAllData } from '../../../../../types/asset';
import { useForceDownload } from '../../../../../utils';
import Point from '../../../Point';

import styles from './index.module.scss';

interface DownloadProps {
  data?: AssetAllData;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Download({
  data, isModalOpen, handleOk, handleCancel,
}: DownloadProps): React.ReactNode {
  const { loading, start } = useForceDownload();

  return (
    <Modal
      width={420}
      title="Usage restrictions information"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalDownload}
    >
      <div className={styles.content}>
        <div className={styles.name}>
          {data?.name}
        </div>

        {data?.restriction ? (
          <div className={styles.list}>
            <Point color="green" text="No restricted usage" />
            <Point color="orange" text="Restricted usage" />
          </div>
        ) : null}
      </div>

      <div>
        <Button
          size="large"
          className={styles.download}
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          className={styles.download}
          loading={loading}
          onClick={(e) => {
            e.preventDefault();

            const href = data?.filesFile?.find(({ publicUrl }) => publicUrl)?.publicUrl || '';
            const name = href.split('/');

            start(href, name[name.length - 1]);
          }}
        >
          Accept
        </Button>
      </div>
    </Modal>
  );
}
