import React, { useState } from 'react';
import { ShareIcon } from '../../../../Common/Icon';
import ShareModal from '../../../../Common/Modal/Collection/Share';

export interface ShareProps {
  id: string;
}

export default function Share({ id }: ShareProps): React.ReactNode {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const ok = () => {
    setIsModalOpen(false);
  };

  const cancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        role="none"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showModal();
        }}
      >
        <ShareIcon />
      </div>
      <ShareModal
        isModalOpen={isModalOpen}
        handleOk={ok}
        handleCancel={cancel}
      />
    </>
  );
}
