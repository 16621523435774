import React, { useEffect, useMemo, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import {
  Checkbox, Input, Pagination, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ArrowBottomIcon, ArrowLeftIcon, ArrowRightIcon, ArrowTopIcon, CloseIcon, SearchIcon,
} from '../Icon';
import { getMinPageSize, useFilter } from '../../Pages/Home/Context/filter';
import { useSearchParams } from '../../../hooks/useSearchParams';
import useComponentDidUpdate from '../../../hooks/componentDidUpdate';

import styles from './index.module.scss';
import { AnyObject } from '../../../types';
import { capitalizeFirstLetter } from '../../../utils';
import { AssetFilterItem } from '../../../types/asset';

const filterOrder = [
  'mediaType',
  'types',
  'keywords',
  'restriction',
  'format',
  'series',
  'systems',
  'language',
  'brands',
  'publicationType',
  'publicationYear',
  'statements',
  'persons',
  'locations',
  'surroundings',
  'reference',
  'tools',
  'event',
  'campaigns',
];

const filterMapping: { [key: string]: string } = {
  mediaType: 'All media',
  types: 'Type',
  keywords: 'Keywords',
  restriction: 'Restriction',
  format: 'All formats',
  series: 'Series',
  systems: 'Systems',
  language: 'Language',
  brands: 'Brand',
  publicationType: 'Publication time',
  publicationYear: 'Publication year',
  statements: 'Statement',
  persons: 'Person',
  locations: 'Location',
  surroundings: 'Surrounding',
  reference: 'Reference',
  tools: 'Apps / Webtools',
  event: 'Event',
  campaigns: 'Campaign',
};

interface OptionProps {
  onClick: (option: string | AssetFilterItem, checked: boolean) => void;
  option: string | AssetFilterItem;
  checked?: boolean;
  disabled?: boolean;
}

function Option({
  disabled = false,
  checked: defaultChecked = false,
  option,
  onClick,
}: OptionProps) {
  const [checked, setChecked] = useState(defaultChecked);

  useComponentDidUpdate(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div
      role="none"
      className={styles.option}
      onClick={(e) => {
        e.preventDefault();
        if (disabled) {
          return;
        }

        onClick(option, !checked);
        setChecked(!checked);
      }}
    >
      <Checkbox
        checked={checked}
        disabled={disabled}
      >
        {capitalizeFirstLetter(
          (typeof option === 'string' ? option : option.text || option.value)
            .replace(/[_\-]+/gim, ' '),
        )}
      </Checkbox>
    </div>
  );
}

interface SelectFilterProps {
  name: string;
  options: string[] | AssetFilterItem[]
}

function SelectFilter({ name, options }: SelectFilterProps) {
  const { filterAvailable } = useFilter();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [_, setSearchParams, searchParams] = useSearchParams({}, false);

  const list = useMemo(() => {
    const listOptions = options.map((option) => ({
      option,
      disabled: filterAvailable?.data && filterAvailable?.data[name]
        ? !filterAvailable?.data[name].includes(typeof option === 'string' ? option : option.id) : true,
    }));

    listOptions.sort(({ disabled }) => (disabled ? 1 : -1));

    return listOptions;
  }, [search, options, filterAvailable?.data]);

  const isDisabled = useMemo(() => !list.some(({ disabled }) => disabled === false), [list]);

  useEffect(() => {
    if (isDisabled) {
      setOpen(false);
    }
  }, [isDisabled]);

  return (
    <div key={name} className={styles.row}>
      <div
        role="none"
        className={clsx(styles.name, {
          [styles.open]: open,
          [styles.disabled]: isDisabled,
        })}
        onClick={(e) => {
          e.preventDefault();

          if (!isDisabled) {
            setOpen(!open);
          }
        }}
      >
        {filterMapping[name]}
        {open ? (
          <ArrowBottomIcon />
        ) : (
          <ArrowRightIcon />
        )}
      </div>
      <div
        className={clsx(styles.list, { [styles.open]: open })}
        style={{ height: (Math.min(options.length, 6) + (options.length > 6 ? 1 : 0)) * 40 + 8 * 2 }}
      >
        {options.length > 6 ? (
          <div className={styles.search}>
            <Input
              allowClear={{
                clearIcon: <CloseIcon style={{ margin: '2px 0 -2px 0', fontSize: 18 }} />,
              }}
              className={styles.search}
              placeholder="Search"
              suffix={<SearchIcon />}
              onChange={(e) => setSearch(e.target.value)}
              size="large"
            />
          </div>
        ) : null}

        <div className={styles.scroll}>
          {(search
            ? list.filter(({ option }) => (typeof option === 'string' ? option : option.text || option.value)
              .toLowerCase()
              .includes(search.toLowerCase()))
            : list).map(({ disabled, option }) => {
            const key = typeof option === 'string' ? option : option.id;

            return (
              <Option
                key={key}
                disabled={disabled}
                checked={searchParams[name] && Array.isArray(searchParams[name])
                  ? searchParams[name].includes(key) : searchParams[name] === key}
                option={option}
                onClick={(opt, checked) => {
                  // eslint-disable-next-line no-nested-ternary
                  const beforeValue = (Array.isArray(searchParams[name])
                    ? searchParams[name] : (searchParams[name] ? [searchParams[name]] : [])) as string[];

                  if (checked) {
                    setSearchParams({
                      ...searchParams,
                      page: '1',
                      [name]: (searchParams[name] ? [...beforeValue, key] : [key]) as any,
                    });
                  } else if (searchParams[name]) {
                    setSearchParams({
                      ...searchParams,
                      page: '1',
                      [name]: beforeValue.filter((v) => v !== key),
                    });
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export interface ContentWrapperProps {
  children?: React.ReactNode
  className?: string | ClassValue;
}

export default function Filter({
  children,
  className = '',
}: ContentWrapperProps): React.ReactNode {
  const { t } = useTranslation();
  const { state: { search } } = useFilter();
  const [value, setValue] = useState<string | undefined>(search);
  const [_, setSearchParams, searchParams, defaultSetSearchParams] = useSearchParams({}, false);
  const {
    state, total, allFilter: { data, error, loading }, isFilterOpen, setFilterOpen,
  } = useFilter();
  const list = useMemo(() => {
    if (data && !error && !loading) {
      return filterOrder.map((key) => ({
        name: key,
        options: data[key] as string[] | AssetFilterItem[],
      })).filter(({ options }) => options
        .some((option) => (typeof option === 'string' ? option : (option?.value || option?.text))));
    }

    return [];
  }, [data]);

  useEffect(() => {
    if (search !== value) {
      setValue(search);
    } else if (!search) {
      setValue('');
    }
  }, [search]);

  const clearSearch = () => {
    const { search: s, ...st } = state;

    setSearchParams({ ...st, page: 1 } as AnyObject);
  };

  const onSubmit = () => {
    setSearchParams({ ...state, search: value || '', page: 1 } as AnyObject);
  };

  return (
    <div className={clsx({ filterOpen: isFilterOpen }, { [styles.filterOpen]: isFilterOpen })}>
      <div className={styles.filter}>
        <div
          className={styles.select}
          role="none"
          onClick={(e) => {
            e.preventDefault();
            setFilterOpen(false);
          }}
        >
          <ArrowTopIcon />
          Filter

          {Object.keys(searchParams).some((key) => !['page', 'pageSize', 'orderBy', 'search'].includes(key)) ? (
            <div
              role="none"
              className={styles.reset}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                defaultSetSearchParams({});
              }}
            >
              Reset all
            </div>
          ) : null}
        </div>

        <span className={styles.result}>
          Result:
          {' '}
          {total}
        </span>

        <form
          style={{ width: 'calc(100% - 16px * 2)' }}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Input
            className={styles.search}
            placeholder="Search"
            suffix={(
              <SearchIcon
                style={{
                  cursor: 'pointer', padding: 10, margin: -11, width: 40, height: 40,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              />
            )}
            allowClear={{
              clearIcon: (
                <CloseIcon
                  style={{
                    margin: '-11px -10px -15px -11px',
                    padding: '11px',
                    width: 40,
                    height: 40,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    clearSearch();
                  }}
                />
              ),
            }}
            onChange={(e) => setValue(e.target.value)}
            value={value || ''}
            size="large"
          />
        </form>

        {loading ? (
          'Loading...'
        ) : null}

        {list.length > 0 ? (
          list.map(({ name, options }) => (
            <SelectFilter
              key={name}
              name={name}
              options={options}
            />
          ))
        ) : null}
      </div>

      <div className={styles.wrp}>
        <div className={clsx(styles.header, { [styles.filterOpen]: isFilterOpen }, className)}>
          {isFilterOpen ? null : (
            <div
              role="none"
              className={styles.select}
              onClick={(e) => {
                e.preventDefault();
                setFilterOpen(true);
              }}
            >
              <ArrowBottomIcon />
              Filter
            </div>
          )}

          <span className={clsx(styles.result, styles.mobileHide)}>
            Result:
            {' '}
            {total}
          </span>

          <div
            className={clsx(
              styles.tabletHide,
              { [styles.hide]: state.pageSize && total && total < state.pageSize },
            )}
          >
            <Pagination
              onChange={(item) => setSearchParams({
                ...searchParams,
                page: item.toString(),
              })}
              current={state.page}
              total={total}
              pageSize={state.pageSize}
              showSizeChanger={false}
              hideOnSinglePage
              showTitle={false}
              nextIcon={<ArrowRightIcon />}
              prevIcon={<ArrowLeftIcon />}
            />
          </div>

          <div className={styles.options}>
            <div className={styles.perpage}>
              <Select<number>
                suffixIcon={<ArrowBottomIcon />}
                onChange={(item) => setSearchParams({
                  ...searchParams,
                  pageSize: Math.max(getMinPageSize(), item).toString(),
                })}
                value={state.pageSize}
                size="large"
                options={[{
                  value: 12,
                  label: '12',
                },
                {
                  value: 24,
                  label: '24',
                },
                {
                  value: 48,
                  label: '48',
                },
                {
                  value: 72,
                  label: '72',
                },
                {
                  value: 96,
                  label: '96',
                },
                {
                  value: 120,
                  label: '120',
                }].filter(({ value: item }) => item >= getMinPageSize())}
              />
            </div>
            <div className={styles.order}>
              <Select
                suffixIcon={<ArrowBottomIcon />}
                onChange={(item) => setSearchParams({
                  ...searchParams,
                  orderBy: item.toString(),
                })}
                value={state.orderBy}
                size="large"
                options={[{
                  value: 'From newest to oldest',
                  label: t('order.from-new-to-old'),
                },
                {
                  value: 'From oldest to newest',
                  label: t('order.from-old-to-new'),
                },
                {
                  value: 'From 0 to 9, A-Z',
                  label: t('order.from-0-9'),
                },
                {
                  value: 'From 9 to 0, Z-A',
                  label: t('order.from-9-0'),
                }]}
              />
            </div>
          </div>

          <div
            className={clsx(
              styles.tabletShow,
              { [styles.hide]: state.pageSize && total < state.pageSize },
            )}
          >
            <Pagination
              onChange={(item) => setSearchParams({
                ...searchParams,
                page: item.toString(),
              })}
              current={state.page}
              total={total}
              pageSize={state.pageSize}
              showSizeChanger={false}
              hideOnSinglePage
              showTitle={false}
              nextIcon={<ArrowRightIcon />}
              prevIcon={<ArrowLeftIcon />}
              totalBoundaryShowSizeChanger={1}
            />
          </div>

          <span className={clsx(styles.result, styles.mobileShow)}>
            Result:
            {' '}
            {total}
          </span>
        </div>

        {children}
      </div>
    </div>
  );
}
