import {
  NavigateOptions,
  SetURLSearchParams,
  URLSearchParamsInit,
  useSearchParams as useRouterSearchParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getValidSearchParamsWithout, ValidSearchParams } from '../utils';
import { AnyObject } from '../types';

export function getSearchParamsWithoutTableProps(
  searchParams: URLSearchParams,
  exclude?: string[] | false,
): ValidSearchParams {
  return getValidSearchParamsWithout(exclude === false ? [] : [
    'page', 'pageSize', 'orderBy', 'orderByColumn',
    ...(exclude || [])], searchParams);
}

export function useWatchSearchParam(name: string): string[] {
  const [searchParams] = useRouterSearchParams();
  const [state, setState] = useState<string[]>(searchParams.getAll(name));

  useEffect(() => {
    const newState = searchParams.getAll(name);

    if (newState.toString() !== state.toString()) {
      setState(newState);
    }
  }, [searchParams]);

  return state;
}

export function useSearchParams(
  defaultInit?: URLSearchParamsInit,
  exclude?: string[] | false,
): [URLSearchParams, SetURLSearchParams, ValidSearchParams, SetURLSearchParams] {
  const [searchParams, setSearchParams] = useRouterSearchParams(defaultInit);
  const [paramsWithoutTableProps, setParamsWithoutTableProps] = useState(
    getSearchParamsWithoutTableProps(searchParams, exclude),
  );

  useEffect(() => {
    const newParams = getSearchParamsWithoutTableProps(searchParams, exclude);

    if (JSON.stringify(paramsWithoutTableProps) !== JSON.stringify(newParams)) {
      setParamsWithoutTableProps(newParams);
    }
  }, [searchParams]);

  return [
    searchParams,
    (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions,
    ) => {
      if (nextInit) {
        if (typeof nextInit === 'object') {
          const keys = Object.keys(nextInit);

          if (keys.length > 0 && keys.some((key) => {
            const next = (nextInit as AnyObject)[key] as string[];
            const params = searchParams.getAll(key);

            if (Array.isArray(next)) {
              if (next.length !== (Array.isArray(params) ? params.length : params)) {
                return true;
              }

              return next.some((value) => !(Array.isArray(params) && params.includes(value)));
            } if (typeof next === 'object' && next !== null) {
              const nextKeys = Object.keys(next);

              return nextKeys.some((nextKey) => next[nextKey] !== params[nextKey as never]);
            }

            return next !== params;
          })) {
            setSearchParams(nextInit, navigateOpts);
          }
        } else {
          setSearchParams(nextInit, navigateOpts);
        }
      }
    },
    paramsWithoutTableProps,
    setSearchParams,
  ];
}

export default {
  useSearchParams,
};
