import React, { useEffect, useState } from 'react';
import { Button, Pagination } from 'antd';
import { NavLink } from 'react-router-dom';
import ContentWrapper from '../../Common/Content/Wrapper';
import Breadcrumbs from '../../Common/Breadcrumbs';
import {
  ArrowLeftIcon, ArrowRightIcon, PlusIcon,
} from '../../Common/Icon';
import NoCollection from '../NoCollection';
import Create from '../../Common/Modal/Collection/Create';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { getMinPageSize } from '../Home/Context/filter';
import { useCollectionGet } from '../../../hooks/api/collection';
import { useMessageError } from '../../../hooks/common';
import Loading from '../../Common/Loading';
import Point from '../../Common/Point';
import Download from './Modal/Download';
import Share from './Modal/Share';
import Delete from './Modal/Delete';

import styles from './index.module.scss';

const pageSize = getMinPageSize();

export default function Collection(): React.ReactNode {
  const [_, setSearchParams, searchParams] = useSearchParams({ page: '1' }, false);
  const [isModalOpenCreate, setIsModalOpenCreate] = useState(false);
  const collectionGet = useCollectionGet();

  useMessageError([collectionGet]);

  const fetchCollection = () => {
    collectionGet.fetch({
      page: Number.parseInt(searchParams.page as string, 10),
      pageSize,
    });
  };

  useEffect(fetchCollection, [searchParams.page]);

  const showModalCreate = () => {
    setIsModalOpenCreate(true);
  };

  const okCreate = () => {
    setIsModalOpenCreate(false);
    fetchCollection();
  };

  const cancelCreate = () => {
    setIsModalOpenCreate(false);
  };

  return (
    <ContentWrapper className={styles.content}>
      <Breadcrumbs
        loading={false}
        list={[
          {
            name: 'Collections',
          },
        ]}
      />

      <div className={styles.header}>
        <div className={styles.title}>
          Collections

          <Button
            icon={<PlusIcon />}
            type="primary"
            size="large"
            onClick={(e) => {
              e.preventDefault();
              showModalCreate();
            }}
          >
            Create New Collection
          </Button>

          <Create
            isModalOpen={isModalOpenCreate}
            handleOk={okCreate}
            handleCancel={cancelCreate}
          />
        </div>
        {collectionGet.data?.meta.itemCount ? (
          <div className={styles.counter}>
            {collectionGet.data?.meta.itemCount}
            {' '}
            collections, 13 Media Assets
          </div>
        ) : null}
      </div>

      {(collectionGet.data?.data
        && collectionGet.data?.data.length > 0
        && !collectionGet.error
      ) || collectionGet.loading ? (
        <>
          <div className={styles.wrp}>
            <div className={styles.list}>
              {collectionGet.data?.data.map(({
                id, name, description, assets,
              }) => {
                let url = '/resource/images/asset.png';

                assets.some((asset) => asset.allData.filesFile?.some(({ publicUrl }) => {
                  if (publicUrl) {
                    url = publicUrl;

                    return true;
                  }

                  return false;
                }));

                return (
                  <NavLink
                    to={`${id}`}
                    key={id}
                    className={styles.item}
                  >
                    <div
                      className={styles.img}
                      style={{
                        backgroundImage: `url('${url}')`,
                      }}
                    />
                    <div className={styles.data}>
                      <div className={styles.name}>
                        {name}
                        <div role="none" className={styles.actions} onClick={(e) => e.stopPropagation()}>
                          <Download id={id} />
                          <Share id={id} />
                          <Delete
                            id={id}
                            onSuccess={fetchCollection}
                          />
                        </div>
                      </div>
                      <div className={styles.description}>
                        {description}
                      </div>
                      <div className={styles.count}>
                        {assets.length}
                        {' '}
                        {assets.length > 1 ? 'Assets' : 'Asset'}
                      </div>
                      <div className={styles.line} />

                      <Point
                        color="orange"
                        className={styles.default}
                        text={(
                          <>
                            At least one default value is not set.
                            <br />
                            Please check before use.
                          </>
                        )}
                      />
                    </div>
                  </NavLink>
                );
              })}
            </div>

            {collectionGet.loading ? (
              <Loading absolute />
            ) : null}
          </div>

          <div className={styles.pagination}>
            <Pagination
              onChange={(item) => setSearchParams({
                ...searchParams,
                page: item.toString(),
              })}
              current={Number.parseInt((searchParams.page as string) || '1', 10)}
              total={collectionGet.data?.meta.itemCount}
              pageSize={pageSize}
              showSizeChanger={false}
              hideOnSinglePage
              showTitle={false}
              nextIcon={<ArrowRightIcon />}
              prevIcon={<ArrowLeftIcon />}
              totalBoundaryShowSizeChanger={1}
            />
          </div>
        </>
        ) : (
          <NoCollection showModalCreate={showModalCreate} />
        )}

      {/* <div className={styles.list}> */}
      {/*  /!* bla *!/ */}
      {/* </div> */}
    </ContentWrapper>
  );
}
