import React from 'react';

import ContentWrapper from '../../Common/Content/Wrapper';

export default function Account(): React.ReactNode {
  return (
    <ContentWrapper>
      Account detail page
    </ContentWrapper>
  );
}
