import React from 'react';
import Layout from '../../components/Layout';
import ContentHome from '../../components/Pages/Home';
import FilterProvider from '../../components/Pages/Home/Context/filter';

export default function Home(): React.ReactNode | null {
  document.title = 'Asset list';

  return (
    <FilterProvider>
      <Layout>
        <ContentHome />
      </Layout>
    </FilterProvider>
  );
}
