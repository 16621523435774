import React from 'react';
import Layout from '../../../components/Layout';

function SignIn(): React.ReactNode {
  document.title = 'Sign in';

  // const { keycloak, initialized } = useKeycloak();
  //
  // useEffect(() => {
  //   if (initialized && !keycloak.authenticated) {
  //     keycloak.login();
  //   }
  // }, [initialized, keycloak.authenticated]);
  //
  // if (!initialized) {
  //   return <Layout>Loading...</Layout>;
  // }

  return (
    <Layout>
      <div>
        Sign in page
      </div>
      {/* <p> */}
      {/*  Welcome, */}
      {/*  {keycloak.tokenParsed?.name} */}
      {/* </p> */}

      {/* <button type="button" onClick={() => keycloak.logout()}>Logout</button> */}
    </Layout>
  );
}

export default SignIn;
